<template>
  <v-card class="ma-3">
    <base-loading v-if="loading" />
    <div v-else>
      <v-card-title style="justify-content: center; font-size: 24px">
        <v-row justify="center">
          <v-col cols="12">
            <v-row justify="center"> Relatório de resíduos de ATB </v-row>
          </v-col>
          <v-col cols="12">
            <v-row justify="center">{{ relatorio.NumeroVisita }}ª visita</v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <InformacoesGeraisATB :relatorio="relatorio" />
      <ComposicaoRebanhoATB :relatorio="relatorio" />
      <IndicadoresQualidadeATB :relatorio="relatorio" />
      <TesteAntibioticoATB :relatorio="relatorio" />
      <IdentificacaoAnimalATB :relatorio="relatorio" />
      <AnotacoesTratamentoATB :relatorio="relatorio" />
      <DivisaoLoteATB :relatorio="relatorio" />
      <TratamentoAnimaisATB :relatorio="relatorio" />
      <ConclusaoATB :relatorio="relatorio" />
      <ProximaVisitaATB :relatorio="relatorio" />
      <v-col cols="12">
        <v-row justify="center">
          <v-btn color="primary" @click="exportPDF(relatorio)">
            <v-icon style="margin-right: 6px"> mdi-file-document </v-icon>
            <span style="font-weight: bold; font-size: 16px">Exportar PDF</span>
          </v-btn>
        </v-row>
      </v-col>
    </div>
  </v-card>
</template>

<script>
import exportPDF from '../../../../services/reports/RelatorioPDFATB'
import { exportReport } from '../../../../utils/report'
import AnotacoesTratamentoATB from './AnotacoesTratamentoATB'
import ComposicaoRebanhoATB from './ComposicaoRebanhoATB'
import ConclusaoATB from './Conclusao.vue'
import DivisaoLoteATB from './DivisaoLoteATB'
import IdentificacaoAnimalATB from './IdentificacaoAnimalATB'
import IndicadoresQualidadeATB from './IndicadoresQualidadeATB'
import InformacoesGeraisATB from './InformacoesGeraisATB'
import ProximaVisitaATB from './ProximaVisitaATB'
import TesteAntibioticoATB from './TesteAntibioticoATB'
import TratamentoAnimaisATB from './TratamentoAnimaisATB'

export default {
  components: {
    IdentificacaoAnimalATB,
    AnotacoesTratamentoATB,
    ComposicaoRebanhoATB,
    TratamentoAnimaisATB,
    DivisaoLoteATB,
    IndicadoresQualidadeATB,
    InformacoesGeraisATB,
    TesteAntibioticoATB,
    ProximaVisitaATB,
    ConclusaoATB,
  },

  props: {
    codRelatorio: {
      type: String,
      default: () => '',
    },
    draft: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: true,
      relatorio: null,
    }
  },
  mounted() {
    if (!this.codRelatorio) {
      this.$router.push({
        path: `/${this.$user.get().role}/relatorios/boaspraticas`,
      })
    } else {
      this.getRelatorio()
    }
  },
  methods: {
    exportPDF,
    getRelatorio() {
      this.api.get.relatorioccs(this.codRelatorio, this.draft).then(data => {
        this.relatorio = data
        this.loading = false
      })
    },
    async exportRel(relatorio) {
      if (!relatorio) relatorio = this.relatorio

      await exportReport({
        report: relatorio,
        exportReport: this.exportPDF,
      })
    },
    alert() {
      this.Swal.fire({
        title: 'Atenção',
        text: 'Em Desenvolvimento!!!',
        icon: 'warning',
      })
    },
  },
}
</script>
