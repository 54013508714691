import HtmlToCanvas from 'html2canvas'
import JsPDF from 'jspdf'
import moment from 'moment'
import { ENV_APP } from '../../../env'
import { convertNumberBR } from '../../utils/masks'

const utilsRelatorio = {
  getDataAtual() {
    var monName = [
      'janeiro',
      'fevereiro',
      'março',
      'abril',
      'maio',
      'junho',
      'julho',
      'agosto',
      'setembro',
      'outubro',
      'novembro',
      'dezembro',
    ]
    var now = new Date()

    var str =
      'Relatório gerado em ' +
      now.getDate() +
      ' de ' +
      monName[now.getMonth()] +
      ' de ' +
      now.getFullYear() +
      ' às ' +
      formatDate(now, 'HH:mm:ss') +
      '.'
    return str
  },
  calcLacVacas(relatorio) {
    if (relatorio.VacasLactacao && relatorio.VacasSecas) {
      return `${convertNumberBR(
        100 *
          (parseInt(relatorio.VacasLactacao) /
            (parseInt(relatorio.VacasLactacao) +
              parseInt(relatorio.VacasSecas))),
      )} %`
    } else {
      return '-'
    }
  },
  calcLacRebanho(relatorio) {
    var totalRebanho = 0
    if (relatorio.VacasLactacao) {
      totalRebanho += parseInt(relatorio.VacasLactacao)
    }
    if (relatorio.VacasSecas) {
      totalRebanho += parseInt(relatorio.VacasSecas)
    }
    if (relatorio.Novilhas) {
      totalRebanho += parseInt(relatorio.Novilhas)
    }
    if (relatorio.Recria) {
      totalRebanho += parseInt(relatorio.Recria)
    }
    if (relatorio.Aleitamento) {
      totalRebanho += parseInt(relatorio.Aleitamento)
    }
    if (relatorio.Machos) {
      totalRebanho += parseInt(relatorio.Machos)
    }
    if (totalRebanho > 0) {
      return `${convertNumberBR(
        100 * (parseInt(relatorio.VacasLactacao) / totalRebanho),
      )} %`
    } else {
      return '-'
    }
  },
  calcProdutividade(relatorio) {
    if (relatorio.VacasLactacao && relatorio.ProducaoMedia) {
      return `${convertNumberBR(
        parseInt(relatorio.ProducaoMedia) / parseInt(relatorio.VacasLactacao),
      )} L / dia`
    } else {
      return '-'
    }
  },
  calcProdutividadeTotal(relatorio) {
    var totalVacas = 0
    if (relatorio.VacasLactacao) {
      totalVacas += parseInt(relatorio.VacasLactacao)
    }
    if (relatorio.VacasSecas) {
      totalVacas += parseInt(relatorio.VacasSecas)
    }
    if (relatorio.ProducaoMedia && totalVacas > 0) {
      return `${convertNumberBR(
        parseInt(relatorio.ProducaoMedia) / totalVacas,
      )} L / dia`
    } else {
      return '-'
    }
  },
  calcProdutividadeDH(relatorio) {
    if (relatorio.NumeroMDO && relatorio.ProducaoMedia) {
      return `${convertNumberBR(
        parseInt(relatorio.ProducaoMedia) / parseInt(relatorio.NumeroMDO),
      )}`
    } else {
      return '-'
    }
  },
}

function formatDate(date, format = 'DD/MM/YYYY') {
  return moment(date).format(format)
}

function renderCheckbox(doc, text, cursor, config) {
  const {
    smallBoxHeight,
    line,
    smallGutterX,
    smallGutterY,
    startX,
    tableLimit,
  } = config

  doc.rect(startX, cursor, tableLimit, line, 'F')

  doc.rect(startX, cursor, line, smallBoxHeight, 'F')

  doc.text(text, startX + smallGutterX, cursor + smallGutterY, {
    maxWidth: tableLimit - smallGutterX * 2,
  })

  doc.rect(startX + tableLimit, cursor, line, smallBoxHeight, 'F')

  doc.rect(startX, cursor + smallBoxHeight, tableLimit, line, 'F')

  return cursor + smallBoxHeight
}

function renderCheckboxList(doc, text, cursor, config) {
  const {
    smallBoxHeight,
    line,
    smallGutterX,
    smallGutterY,
    startX,
    tableLimit,
  } = config

  doc.rect(startX, cursor, line, smallBoxHeight, 'F')

  doc.text(text, startX + smallGutterX, cursor + smallGutterY, {
    maxWidth: tableLimit - smallGutterX * 2,
  })

  doc.rect(startX + tableLimit, cursor, line, smallBoxHeight, 'F')
}

function renderRecomendacoes(doc, text, data, cursor, config) {
  const {
    smallBoxHeight,
    line,
    smallGutterX,
    smallGutterY,
    gutterY,
    startX,
    tableLimit,
  } = config

  if (!data) return cursor

  data = data || '-'
  const width = doc.getTextWidth(data)
  const numberLines = parseInt(width / (tableLimit - smallGutterX * 2) + 2)

  cursor = checkAddPage(doc, cursor, smallBoxHeight * numberLines, config)

  doc.rect(startX, cursor, line, smallBoxHeight * numberLines, 'F')

  doc.setFont('helvetica', 'normal')
  doc.text(text, startX + smallGutterX, cursor + smallGutterY)

  doc.text(data, startX + smallGutterX, cursor + gutterY, {
    maxWidth: tableLimit - smallGutterX * 2,
  })

  doc.rect(startX + tableLimit, cursor, line, smallBoxHeight * numberLines, 'F')

  doc.rect(startX, cursor + smallBoxHeight * numberLines, tableLimit, line, 'F')

  cursor = checkAddPage(doc, cursor, smallBoxHeight * numberLines, config)

  return cursor + smallBoxHeight * numberLines
}

function checkAddPage(doc, cursor, area, config) {
  const { heightLimit, margin, tableLimit, line, startX } = config

  if (cursor + area > heightLimit) {
    doc.addPage()
    cursor = margin
    doc.rect(startX, cursor, tableLimit, line, 'F')
  }

  return cursor
}

function header(doc, config, relatorio) {
  const logo1 = new Image()
  const logo2 = new Image()

  logo1.src = config.logo

  doc.addImage(logo1, 'PNG', config.margin, config.margin, 70, 55)

  if (relatorio.logoagroindustria) {
    logo2.src = relatorio.logoagroindustria
    doc.addImage(logo2, 'PNG', config.tableLimit - 50, config.margin, 73, 50)
  }

  doc.setFontSize(config.largefontSize).setFont('helvetica', 'bold')

  const widthOfString = doc.getTextWidth('Relatório de resíduos de ATB')

  doc.text(
    'Relatório de resíduos de ATB',
    config.tableLimit / 2 - widthOfString / 2 + config.margin,
    config.margin + 30,
  )

  doc.setFontSize(config.fontSize).setFont('helvetica', 'normal')
}

function subHeader(doc, _ref, _ref2) {
  const {
    fazenda: {
      produtor: { Nome: produtor },
      pessoajuridica: { NomeFantasia: fazenda },
      producer: { Matricula: matricula },
    },
    consultor: {
      pessoafisica: { Nome: consultor },
    },
    cidadefazenda,
    DataAtendimento,
    HoraInicio,
    HoraFim,
    NumeroVisita,
  } = _ref
  const {
    boxHeight,
    gutterY,
    line,
    lineColor,
    smallGutterX,
    smallGutterY,
    startX,
    startY,
    tableLimit,
    mediumFontSize,
    fontSize,
  } = _ref2

  doc.setFillColor(lineColor)

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Informações básicas', startX, startY + 10)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  doc.rect(startX, startY + boxHeight, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight, line, boxHeight, 'F')

  doc.text('Fazenda:', startX + smallGutterX, startY + smallGutterY + boxHeight)

  doc
    .setFont('helvetica', 'bold')
    .text(fazenda, startX + smallGutterX, startY + gutterY + boxHeight)
    .setFont('helvetica', 'normal')

  doc.rect(startX, startY + boxHeight * 2, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.rect(startX + tableLimit, startY + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Produtor(a):',
    startX + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    produtor.substring(0, 25),
    startX + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 3, tableLimit, line, 'F')

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 2,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Matrícula:',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    matricula ? matricula : '-',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.text(
    'Consultor(a):',
    startX + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(consultor, startX + smallGutterX, startY + boxHeight * 3 + gutterY)

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 3,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Cidade:',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    cidadefazenda ? cidadefazenda.Nome : '-',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 4, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 4, line, boxHeight, 'F')

  doc.text(
    'Data:',
    startX + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    DataAtendimento,
    startX + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 4,
    startY + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Horário do início:',
    startX + tableLimit / 4 + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    HoraInicio,
    startX + tableLimit / 4 + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Horário do término:',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    HoraFim,
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 4,
    startY + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Número da visita:',
    startX + tableLimit - tableLimit / 4 + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    `${NumeroVisita}ª visita`,
    startX + tableLimit - tableLimit / 4 + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 4, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 5, tableLimit, line, 'F')
}

function indicadoresQualidade(doc, relatorio, _ref2) {
  const {
    boxHeight,
    fontSize,
    line,
    gutterY,
    mediumFontSize,
    smallGutterX,
    smallGutterY,
    startX,
    startY,
    tableLimit,
  } = _ref2

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Principais Indicadores', startX, startY + 10)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  doc.rect(startX, startY + boxHeight, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Produção média (l/dia):',
    startX + smallGutterX,
    startY + boxHeight + smallGutterY,
  )

  doc.text(
    relatorio.ProducaoMedia
      ? `${relatorio.ProducaoMedia} L / Dia`
      : 'Não informado',
    startX + smallGutterX,
    startY + boxHeight + gutterY,
  )

  doc.rect(startX + tableLimit / 3, startY + boxHeight, line, boxHeight, 'F')

  doc.text(
    'CPP média:',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight + smallGutterY,
  )

  doc.text(
    relatorio.CppMedia ? `${relatorio.CppMedia} UFC/ml * 10³` : 'Não informado',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    startY + boxHeight,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'CCS média:',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight + smallGutterY,
  )

  doc.text(
    relatorio.CcsMedia
      ? `${relatorio.CcsMedia} céls/ml * 10³`
      : 'Não informado',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 2, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.text(
    'Número de pessoas envolvidas no manejo de rebanho:',
    startX + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    relatorio.NumeroMDO ? `${relatorio.NumeroMDO}` : 'Não informado',
    startX + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 2,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Área para produção de leite (ha):',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    relatorio.AreaProducao ? `${relatorio.AreaProducao} ha` : 'Não informado',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 3, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.text(
    'Produtividade/DH:',
    startX + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    `${utilsRelatorio.calcProdutividadeDH(relatorio)}`,
    startX + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 3,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Produtividade / Área:',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    relatorio.ProducaoMedia && relatorio.AreaProducao
      ? `${convertNumberBR(
          (parseInt(relatorio.ProducaoMedia) * 365) /
            parseInt(relatorio.AreaProducao),
        )} L / ha / ano`
      : '-',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 4, tableLimit, line, 'F')
}

function composicaoRebanho(doc, relatorio, config, cursor) {
  const {
    boxHeight,
    fontSize,
    line,
    gutterY,
    mediumFontSize,
    smallGutterX,
    smallGutterY,
    startX,
    startY,
    tableLimit,
  } = config

  cursor = startY + boxHeight + 180

  cursor = checkAddPage(doc, cursor, boxHeight, config)

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Composição do rebanho', startX, startY + 10)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  doc.rect(startX, cursor + boxHeight, tableLimit, line, 'F')

  doc.rect(startX, cursor + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Vacas em lactação:',
    startX + smallGutterX,
    cursor + boxHeight + smallGutterY,
  )

  doc.text(
    `${relatorio.VacasLactacao}`,
    startX + smallGutterX,
    cursor + boxHeight + gutterY,
  )

  doc.rect(startX + tableLimit / 3, cursor + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Vacas secas:',
    startX + tableLimit / 3 + smallGutterX,
    cursor + boxHeight + smallGutterY,
  )

  doc.text(
    `${relatorio.VacasSecas}`,
    startX + tableLimit / 3 + smallGutterX,
    cursor + boxHeight + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    cursor + boxHeight,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Novilhas:',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    cursor + boxHeight + smallGutterY,
  )

  doc.text(
    `${relatorio.Novilhas}`,
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    cursor + boxHeight + gutterY,
  )

  doc.rect(startX + tableLimit, cursor + boxHeight, line, boxHeight, 'F')

  doc.rect(startX, cursor + boxHeight * 2, tableLimit, line, 'F')

  doc.rect(startX, cursor + boxHeight * 2, line, boxHeight, 'F')

  doc.text(
    'Machos:',
    startX + smallGutterX,
    cursor + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    `${relatorio.Machos}`,
    startX + smallGutterX,
    cursor + boxHeight * 2 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 3,
    cursor + boxHeight * 2,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Animais em recria:',
    startX + tableLimit / 3 + smallGutterX,
    cursor + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    `${relatorio.Recria}`,
    startX + tableLimit / 3 + smallGutterX,
    cursor + boxHeight * 2 + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    cursor + boxHeight * 2,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Animais em aleitamento:',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    cursor + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    `${relatorio.Aleitamento}`,
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    cursor + boxHeight * 2 + gutterY,
  )

  doc.rect(startX + tableLimit, cursor + boxHeight * 2, line, boxHeight, 'F')

  doc.rect(startX, cursor + boxHeight * 3, tableLimit, line, 'F')

  doc.rect(startX, cursor + boxHeight * 3, line, boxHeight, 'F')

  doc.text(
    'Vacas em lactação / total de vacas:',
    startX + smallGutterX,
    cursor + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    `${utilsRelatorio.calcLacVacas(relatorio)}`,
    startX + smallGutterX,
    cursor + boxHeight * 3 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 2,
    cursor + boxHeight * 3,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Vacas em lactação / total do rebanho:',
    startX + tableLimit / 2 + smallGutterX,
    cursor + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    `${utilsRelatorio.calcLacRebanho(relatorio)}`,
    startX + tableLimit / 2 + smallGutterX,
    cursor + boxHeight * 3 + gutterY,
  )

  doc.rect(startX + tableLimit, cursor + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(startX, cursor + boxHeight * 4, tableLimit, line, 'F')

  doc.rect(startX, cursor + boxHeight * 4, line, boxHeight, 'F')

  doc.text(
    'Produtividade / vacas em lactação:',
    startX + smallGutterX,
    cursor + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    `${utilsRelatorio.calcProdutividade(relatorio)}`,
    startX + smallGutterX,
    cursor + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 2,
    cursor + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Produtividade / total de vacas:',
    startX + tableLimit / 2 + smallGutterX,
    cursor + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    `${utilsRelatorio.calcProdutividadeTotal(relatorio)}`,
    startX + tableLimit / 2 + smallGutterX,
    cursor + boxHeight * 4 + gutterY,
  )

  doc.rect(startX + tableLimit, cursor + boxHeight * 4, line, boxHeight, 'F')

  doc.rect(startX, cursor + boxHeight * 5, tableLimit, line, 'F')

  cursor += cursor + boxHeight

  return cursor + boxHeight
}

function testeAntibiotico(doc, relatorio, config, cursor) {
  const {
    fontSize,
    mediumFontSize,
    startX,
    smallBoxHeight,
    tableLimit,
    line,
    boxHeight,
    heightLimit,
    margin,
  } = config

  if (relatorio.TesteAntibiótico || relatorio.TesteAntibióticoObs) {
    cursor += config.boxHeight * 22.5

    if (cursor + boxHeight * 20 > heightLimit) {
      doc.addPage()
      cursor = margin + 10
    }

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Teste de antibiótico', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight

    if (relatorio.TesteAntibiótico == 'confirm') {
      renderCheckbox(
        doc,
        '(V) O leite testado apresentou resultado positivo para antibiótico',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      if (relatorio.TesteAntibióticoRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Aminoglicosídeos. Principais fármacos: Neomicina, Estreptomicina e Gentamicina',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.TesteAntibióticoRecomendacao2) {
        renderCheckboxList(
          doc,
          '  Quinolonas. Principais Fármacos: Enrofloxacina, Ciprofloxacina e Norfloxacina',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.TesteAntibióticoRecomendacao3) {
        renderCheckboxList(
          doc,
          '  Cefalosporinas. Principais Fármacos: Ceftiofur; Cefquinoma e Cefaperazone',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.TesteAntibióticoRecomendacao4) {
        renderCheckboxList(
          doc,
          '  Penicilinas. Principais Fármacos: Penicila, Amoxicilina e Cloxacilina',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.TesteAntibióticoRecomendacao5) {
        renderCheckboxList(
          doc,
          '  Tetraciclinas. Principais Fármacos: Oxitetraciclina',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.TesteAntibióticoRecomendacao6) {
        renderCheckboxList(
          doc,
          '  Sulfonamidas. Principais Fármacos: Sulfas e Tremitropina',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    } else if (relatorio.TesteAntibiótico != '') {
      renderCheckbox(
        doc,
        '(X) O leite testado apresentou resultado positivo para antibiótico',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    if (relatorio.TesteAntibióticoObs != '') {
      doc.setFont('helvetica', 'bold')
      renderCheckboxList(doc, ' • Observação:', cursor, config)
      doc.setFont('helvetica', 'normal')
      cursor += smallBoxHeight - 12
      cursor = renderRecomendacoes(
        doc,
        '',
        relatorio.TesteAntibióticoObs.replace(/(\r\n|\n|\r)/gm, ''),
        cursor,
        config,
      )
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }
    cursor += smallBoxHeight
  }

  return cursor
}

function identificacaoAnimal(doc, relatorio, config, cursor) {
  const {
    boxHeight,
    fontSize,
    mediumFontSize,
    startX,
    smallBoxHeight,
    heightLimit,
    margin,
    tableLimit,
    line,
  } = config

  if (
    relatorio.IdentificacaoAnimalPorBrinco ||
    relatorio.IdentificacaoVisualTratamento ||
    relatorio.IdentificacaoVisualColostro ||
    relatorio.IdentificacaoVisualNaoOrdenha ||
    relatorio.IdentificacaoObs
  ) {
    cursor += config.boxHeight

    if (cursor + boxHeight * 10 > heightLimit) {
      doc.addPage()
      cursor = margin + 10
    }

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Identificação dos animais', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight

    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

    if (relatorio.IdentificacaoAnimalPorBrinco == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Os animais possuem identificação por brinco',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.IdentificacaoAnimalPorBrinco != '') {
      renderCheckbox(
        doc,
        '(X) Os animais possuem identificação por brinco',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      doc.setFont('helvetica', 'bold')
      renderCheckboxList(doc, ' • Recomendações:', cursor, config)
      doc.setFont('helvetica', 'normal')

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.IdentificacaoAnimalPorBrincoRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Identificar os animais por brinco',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        doc.rect(startX, cursor, tableLimit, line, 'F')
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.IdentificacaoVisualTratamento == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Os animais medicados apresentam algum tipo de marcação visual que identifique a realização de tratamento',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.IdentificacaoVisualTratamento != '') {
      renderCheckbox(
        doc,
        '(X) Os animais medicados apresentam algum tipo de marcação visual que identifique a realização de tratamento',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      doc.setFont('helvetica', 'bold')
      renderCheckboxList(doc, ' • Recomendações:', cursor, config)
      doc.setFont('helvetica', 'normal')

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.IdentificacaoVisualTratamentoRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Marcar os animais tratados. Exemplo: marcar com pulseira ou tinta vermelha',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        doc.rect(startX, cursor, tableLimit, line, 'F')
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.IdentificacaoVisualColostro == 'confirm') {
      cursor = renderRecomendacoes(
        doc,
        '',
        '(V) Os animais recém paridos possuem algum tipo de marcação visual que identifique a presença de colostro ou leite de transição',
        cursor,
        config,
      )
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.IdentificacaoVisualColostro != '') {
      cursor = renderRecomendacoes(
        doc,
        '',
        '(X) Os animais recém paridos possuem algum tipo de marcação visual que identifique a presença de colostro ou leite de transição',
        cursor,
        config,
      )
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      doc.setFont('helvetica', 'bold')
      renderCheckboxList(doc, ' • Recomendações:', cursor, config)
      doc.setFont('helvetica', 'normal')

      cursor += smallBoxHeight - 5
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.IdentificacaoVisualColostroRecomendacao1) {
        cursor = renderRecomendacoes(
          doc,
          '',
          '  Marcar os animais recém paridos em produção de colostro ou leite de transição. Exemplo: marcar com pulseira ou tinta amarela',
          cursor,
          config,
        )
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.IdentificacaoVisualNaoOrdenha == 'confirm') {
      renderCheckbox(
        doc,
        '(V) As vacas secas possuem algum tipo de marcação visual que identifique que aquele animal não pode ser ordenhado',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.IdentificacaoVisualNaoOrdenha != '') {
      renderCheckbox(
        doc,
        '(X) As vacas secas possuem algum tipo de marcação visual que identifique que aquele animal não pode ser ordenhado',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      doc.setFont('helvetica', 'bold')
      renderCheckboxList(doc, ' • Recomendações:', cursor, config)
      doc.setFont('helvetica', 'normal')

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.IdentificacaoVisualNaoOrdenhaRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Marcar os animais secos. Exemplo:  marcar com pulseira ou tinta azul',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        doc.rect(startX, cursor, tableLimit, line, 'F')
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }
    if (relatorio.IdentificacaoObs != '') {
      doc.setFont('helvetica', 'bold')
      renderCheckboxList(doc, ' • Observação:', cursor, config)
      doc.setFont('helvetica', 'normal')
      cursor += smallBoxHeight - 12
      cursor = renderRecomendacoes(
        doc,
        '',
        relatorio.IdentificacaoObs.replace(/(\r\n|\n|\r)/gm, ''),
        cursor,
        config,
      )
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }
    cursor += smallBoxHeight
  }
  return cursor
}

function anotacoes(doc, relatorio, config, cursor) {
  const {
    boxHeight,
    fontSize,
    mediumFontSize,
    startX,
    smallBoxHeight,
    heightLimit,
    margin,
    tableLimit,
    line,
  } = config

  if (
    relatorio.AnotacoesTratamento ||
    relatorio.RegistroDeSecagem ||
    relatorio.RegistrosAtualizados ||
    relatorio.AnotacoesObs
  ) {
    cursor += config.boxHeight

    if (cursor + boxHeight * 6 > heightLimit) {
      doc.addPage()
      cursor = margin + 10
    }

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Anotações de tratamentos', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight

    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

    if (relatorio.AnotacoesTratamento == 'confirm') {
      renderCheckbox(
        doc,
        '(V) A propriedade possui registro de secagem dos animais',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.AnotacoesTratamento != '') {
      renderCheckbox(
        doc,
        '(X) A propriedade possui registro de secagem dos animais',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      doc.setFont('helvetica', 'bold')
      renderCheckboxList(doc, ' • Recomendações:', cursor, config)
      doc.setFont('helvetica', 'normal')

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.AnotacoesTratamentoRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Iniciar o registro de tratamento dos animais (caderno, ficha ou software)',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        doc.rect(startX, cursor, tableLimit, line, 'F')
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.RegistroDeSecagem == 'confirm') {
      renderCheckbox(
        doc,
        '(V) A propriedade possui registro de secagem dos animais',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.RegistroDeSecagem != '') {
      renderCheckbox(
        doc,
        '(X) A propriedade possui registro de secagem dos animais',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      doc.setFont('helvetica', 'bold')
      renderCheckboxList(doc, ' • Recomendações:', cursor, config)
      doc.setFont('helvetica', 'normal')

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.RegistroDeSecagemRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Iniciar o registro de secagem dos animais (caderno, ficha ou software)',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        doc.rect(startX, cursor, tableLimit, line, 'F')
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.RegistrosAtualizados == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Os registros são atualizado frequentemente (no mínimo semanal)',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.RegistrosAtualizados != '') {
      renderCheckbox(
        doc,
        '(X) Os registros são atualizado frequentemente (no mínimo semanal)',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      doc.setFont('helvetica', 'bold')
      renderCheckboxList(doc, ' • Recomendações:', cursor, config)
      doc.setFont('helvetica', 'normal')

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.RegistrosAtualizadosRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Atualizar os registro com frequência (no mínimo semanal)',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        doc.rect(startX, cursor, tableLimit, line, 'F')
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    if (relatorio.AnotacoesObs != '') {
      doc.setFont('helvetica', 'bold')
      renderCheckboxList(doc, ' • Observação:', cursor, config)
      doc.setFont('helvetica', 'normal')
      cursor += smallBoxHeight - 12
      cursor = renderRecomendacoes(
        doc,
        '',
        relatorio.AnotacoesObs.replace(/(\r\n|\n|\r)/gm, ''),
        cursor,
        config,
      )
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }
  }

  cursor += smallBoxHeight

  if (cursor + boxHeight * 11 > heightLimit) {
    doc.addPage()
    cursor = margin
  }

  return cursor
}

function divisaoLote(doc, relatorio, config, cursor) {
  const {
    fontSize,
    mediumFontSize,
    startX,
    smallBoxHeight,
    tableLimit,
    line,
  } = config

  if (
    relatorio.LoteEspecifico ||
    relatorio.AnimaisSeparadosPeriodoCarencia ||
    relatorio.PropriedadeLinhaOrdenha ||
    relatorio.DivisaoLoteObs
  ) {
    cursor += config.boxHeight

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Divisão de lotes', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight

    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

    if (relatorio.LoteEspecifico == 'confirm') {
      renderCheckbox(
        doc,
        '(V) A propriedade possui um lote específico para animais em tratamento',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.LoteEspecifico != '') {
      renderCheckbox(
        doc,
        '(X) A propriedade possui um lote específico para animais em tratamento',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      doc.setFont('helvetica', 'bold')
      renderCheckboxList(doc, ' • Recomendações:', cursor, config)
      doc.setFont('helvetica', 'normal')

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.LoteEspecificoRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Separar os animais em lotes específicos de tratamento',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        doc.rect(startX, cursor, tableLimit, line, 'F')
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.AnimaisSeparadosPeriodoCarencia == 'confirm') {
      renderCheckbox(
        doc,
        '(V) A propriedade mantém os animais separados até o término do período de carência',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.AnimaisSeparadosPeriodoCarencia != '') {
      renderCheckbox(
        doc,
        '(X) A propriedade mantém os animais separados até o término do período de carência',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      doc.setFont('helvetica', 'bold')
      renderCheckboxList(doc, ' • Recomendações:', cursor, config)
      doc.setFont('helvetica', 'normal')

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.AnimaisSeparadosPeriodoCarenciaRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Manter os animais no lote de tratamento até terminar o período de carência do medicamento',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        doc.rect(startX, cursor, tableLimit, line, 'F')
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.PropriedadeLinhaOrdenha == 'confirm') {
      renderCheckbox(
        doc,
        '(V) A propriedade faz linha de ordenha (os animais tratados e/ou em carência são ordenhado no final)',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.PropriedadeLinhaOrdenha != '') {
      renderCheckbox(
        doc,
        '(X) A propriedade faz linha de ordenha (os animais tratados e/ou em carência são ordenhado no final)',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      doc.setFont('helvetica', 'bold')
      renderCheckboxList(doc, ' • Recomendações:', cursor, config)
      doc.setFont('helvetica', 'normal')

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.PropriedadeLinhaOrdenhaRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Realizar a ordenha dos animais tratados ou em período de carência por último',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        doc.rect(startX, cursor, tableLimit, line, 'F')
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }
    if (relatorio.DivisaoLoteObs != '') {
      doc.setFont('helvetica', 'bold')
      renderCheckboxList(doc, ' • Observação:', cursor, config)
      doc.setFont('helvetica', 'normal')
      cursor += smallBoxHeight - 12
      cursor = renderRecomendacoes(
        doc,
        '',
        relatorio.DivisaoLoteObs.replace(/(\r\n|\n|\r)/gm, ''),
        cursor,
        config,
      )
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }
    cursor += smallBoxHeight
  }
  return cursor
}

function instalacaoEGestao(doc, relatorio, config, cursor) {
  const {
    boxHeight,
    fontSize,
    mediumFontSize,
    startX,
    smallBoxHeight,
    heightLimit,
    margin,
    tableLimit,
    line,
  } = config

  if (
    relatorio.TratamentoFeitoAposOrdenha ||
    relatorio.AnimaisIndicacaoVeterinario ||
    relatorio.TratamentoComBula ||
    relatorio.SepararLeiteAnimaisTratamento ||
    relatorio.RespeitarPeriodoBula ||
    relatorio.TratamentoAnimaisObs
  ) {
    cursor += config.boxHeight

    if (cursor + boxHeight * 6 > heightLimit) {
      doc.addPage()
      cursor = margin
    }

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Tratamento dos animais', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight

    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

    if (relatorio.TratamentoFeitoAposOrdenha == 'confirm') {
      renderCheckbox(
        doc,
        '(V) O tratamento é feito após a ordenha dos animais sadios',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.TratamentoFeitoAposOrdenha != '') {
      renderCheckbox(
        doc,
        '(X) O tratamento é feito após a ordenha dos animais sadios',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      doc.setFont('helvetica', 'bold')
      renderCheckboxList(doc, ' • Recomendações:', cursor, config)
      doc.setFont('helvetica', 'normal')

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.TratamentoFeitoAposOrdenhaRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Realizar o tratamento dos animais enfermos após a ordenha dos animais sadios',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        doc.rect(startX, cursor, tableLimit, line, 'F')
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.AnimaisIndicacaoVeterinario == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Os tratamentos tem indicação de médico veterinário',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.AnimaisIndicacaoVeterinario != '') {
      renderCheckbox(
        doc,
        '(X) Os tratamentos tem indicação de médico veterinário',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      doc.setFont('helvetica', 'bold')
      renderCheckboxList(doc, ' • Recomendações:', cursor, config)
      doc.setFont('helvetica', 'normal')

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.AnimaisIndicacaoVeterinarioRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Procurar um médico veterinário para que possa orientar o tratamento correto aos animais',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        doc.rect(startX, cursor, tableLimit, line, 'F')
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }

      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.TratamentoComBula == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Os tratamentos são administrados de acordo com a bula',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.TratamentoComBula != '') {
      renderCheckbox(
        doc,
        '(X) Os tratamentos são administrados de acordo com a bula',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      doc.setFont('helvetica', 'bold')
      renderCheckboxList(doc, ' • Recomendações:', cursor, config)
      doc.setFont('helvetica', 'normal')

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.TratamentoComBulaRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Sempre olhar a bula do medicamento antes de aplica-lo',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        doc.rect(startX, cursor, tableLimit, line, 'F')
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.SepararLeiteAnimaisTratamento == 'confirm') {
      cursor = renderRecomendacoes(
        doc,
        '',
        '(V) O leite dos animais em tratamento e/ou carência é direcionado para latões ou baldes que não irão para o tanque de refriamento',
        cursor,
        config,
      )
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.SepararLeiteAnimaisTratamento != '') {
      cursor = renderRecomendacoes(
        doc,
        '',
        '(X) O leite dos animais em tratamento e/ou carência é direcionado para latões ou baldes que não irão para o tanque de refriamento',
        cursor,
        config,
      )
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      doc.setFont('helvetica', 'bold')
      renderCheckboxList(doc, ' • Recomendações:', cursor, config)
      doc.setFont('helvetica', 'normal')

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.SepararLeiteAnimaisTratamentoRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Separar o leite dos animais em tratamento ou carência',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        doc.rect(startX, cursor, tableLimit, line, 'F')
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.RespeitarPeriodoBula == 'confirm') {
      renderCheckbox(
        doc,
        '(V) A propriedade respeita o período de carência de acordo com a bula para liberar os animais para ordenha',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.RespeitarPeriodoBula != '') {
      renderCheckbox(
        doc,
        '(X) A propriedade respeita o período de carência de acordo com a bula para liberar os animais para ordenha',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      doc.setFont('helvetica', 'bold')
      renderCheckboxList(doc, ' • Recomendações:', cursor, config)
      doc.setFont('helvetica', 'normal')

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.RespeitarPeriodoBulaRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Sempre respeitar o período de carência descrito na bula do medicamento ou orientação do médico veterinário antes de liberar o leite para o tanque',
          cursor,
          config,
        )
        doc.rect(startX, cursor, line, boxHeight + 10, 'F') // esquerda
        doc.rect(startX + tableLimit, cursor, line, boxHeight + 10, 'F') // direita
        cursor += smallBoxHeight * 2
        doc.rect(startX, cursor, tableLimit, line, 'F')
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    if (relatorio.TratamentoAnimaisObs != '') {
      doc.setFont('helvetica', 'bold')
      renderCheckboxList(doc, ' • Observação:', cursor, config)
      doc.setFont('helvetica', 'normal')
      cursor += smallBoxHeight - 12
      cursor = renderRecomendacoes(
        doc,
        '',
        relatorio.TratamentoAnimaisObs.replace(/(\r\n|\n|\r)/gm, ''),
        cursor,
        config,
      )
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor += smallBoxHeight

    if (cursor + boxHeight * 11 > heightLimit) {
      doc.addPage()
      cursor = margin
    }
  }
  return cursor
}
/* eslint-disable */
function conclusao(doc, relatorio, config, cursor) {
  const {
    boxHeight,
    heightLimit,
    margin,
    mediumFontSize,
    startX,
    fontSize,
    smallBoxHeight,
    tableLimit,
    line,
  } = config

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Conclusão', startX, cursor)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  cursor += smallBoxHeight

  // render text Qual a(s) principal(ais) causa(s) da contaminação do leite por antibiótico?
  if (relatorio.TesteAntibiótico || relatorio.TesteAntibióticoObs) {
    if (relatorio.TesteAntibiótico == 'confirm') {
      renderCheckbox(
        doc,
        'Qual a(s) principal(ais) causa(s) da contaminação do leite por antibiótico?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      if (relatorio.TesteAntibióticoRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Aminoglicosídeos. Principais fármacos: Neomicina, Estreptomicina e Gentamicina',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.TesteAntibióticoRecomendacao2) {
        renderCheckboxList(
          doc,
          '  Quinolonas. Principais Fármacos: Enrofloxacina, Ciprofloxacina e Norfloxacina',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.TesteAntibióticoRecomendacao3) {
        renderCheckboxList(
          doc,
          '  Cefalosporinas. Principais Fármacos: Ceftiofur; Cefquinoma e Cefaperazone',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.TesteAntibióticoRecomendacao4) {
        renderCheckboxList(
          doc,
          '  Penicilinas. Principais Fármacos: Penicila, Amoxicilina e Cloxacilina',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.TesteAntibióticoRecomendacao5) {
        renderCheckboxList(
          doc,
          '  Tetraciclinas. Principais Fármacos: Oxitetraciclina',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.TesteAntibióticoRecomendacao6) {
        renderCheckboxList(
          doc,
          '  Sulfonamidas. Principais Fármacos: Sulfas e Tremitropina',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    } else {
      renderCheckbox(
        doc,
        'Não ah nenhuma contaminação do leite por antibiótico',
        cursor,
        config,
      )
    }
    cursor += smallBoxHeight
  }

  if (
    relatorio['IdentificacaoAnimalPorBrincoRecomendacao1'] &&
    relatorio['IdentificacaoVisualTratamentoRecomendacao1'] &&
    relatorio['IdentificacaoVisualColostroRecomendacao1'] &&
    relatorio['IdentificacaoVisualNaoOrdenhaRecomendacao1'] &&
    relatorio['AnotacoesTratamentoRecomendacao1'] &&
    relatorio['RegistroDeSecagemRecomendacao1'] &&
    relatorio['RegistrosAtualizadosRecomendacao1'] &&
    relatorio['LoteEspecificoRecomendacao1'] &&
    relatorio['AnimaisSeparadosPeriodoCarenciaRecomendacao1'] &&
    relatorio['PropriedadeLinhaOrdenhaRecomendacao1'] &&
    relatorio['TratamentoFeitoAposOrdenhaRecomendacao1'] &&
    relatorio['AnimaisIndicacaoVeterinarioRecomendacao1'] &&
    relatorio['TratamentoComBulaRecomendacao1'] &&
    relatorio['SepararLeiteAnimaisTratamentoRecomendacao1'] &&
    relatorio['RespeitarPeriodoBulaRecomendacao1']
  ) {
    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    renderCheckbox(
      doc,
      'Qual a(s) principal(ais) ação(ões) a serem tomadas para que não ocorra novamente?',
      cursor,
      config,
    )

    cursor += smallBoxHeight

    if (relatorio['IdentificacaoAnimalPorBrincoRecomendacao1']) {
      renderCheckboxList(
        doc,
        '  Identificar os animais por brinco',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio['IdentificacaoVisualTratamentoRecomendacao1']) {
      renderCheckboxList(
        doc,
        '  Identificar visualmente os animais em tratamento',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio['IdentificacaoVisualColostroRecomendacao1']) {
      renderCheckboxList(
        doc,
        '  Identificar visualmente o colostro dos animais em tratamento',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio['IdentificacaoVisualNaoOrdenhaRecomendacao1']) {
      renderCheckboxList(
        doc,
        '  Identificar visualmente os animais que não devem ser ordenhados',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio['AnotacoesTratamentoRecomendacao1']) {
      renderCheckboxList(
        doc,
        '  Iniciar o registro de tratamento dos animais (caderno, ficha ou software)',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio['RegistroDeSecagemRecomendacao1']) {
      renderCheckboxList(
        doc,
        '  Iniciar o registro de secagem dos animais (caderno, ficha ou software)',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio['RegistrosAtualizadosRecomendacao1']) {
      renderCheckboxList(
        doc,
        '  Atualizar os registro com frequência (no mínimo semanal)',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio['LoteEspecificoRecomendacao1']) {
      renderCheckboxList(
        doc,
        '  Separar os animais em lotes específicos de tratamento',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio['AnimaisSeparadosPeriodoCarenciaRecomendacao1']) {
      renderCheckboxList(
        doc,
        '  Manter os animais no lote de tratamento até terminar o período de carência do medicamento',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio['PropriedadeLinhaOrdenhaRecomendacao1']) {
      renderCheckboxList(
        doc,
        '  Realizar a ordenha dos animais tratados ou em período de carência por último',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio['TratamentoFeitoAposOrdenhaRecomendacao1']) {
      renderCheckboxList(
        doc,
        '  Realizar o tratamento dos animais enfermos após a ordenha dos animais sadios',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio['AnimaisIndicacaoVeterinarioRecomendacao1']) {
      renderCheckboxList(
        doc,
        '  Procurar um médico veterinário para que possa orientar o tratamento correto aos animais',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio['TratamentoComBulaRecomendacao1']) {
      renderCheckboxList(
        doc,
        '  Sempre olhar a bula do medicamento antes de aplica-lo',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio['SepararLeiteAnimaisTratamentoRecomendacao1']) {
      renderCheckboxList(
        doc,
        '  Separar o leite dos animais em tratamento ou carência',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio['RespeitarPeriodoBulaRecomendacao1']) {
      renderCheckboxList(
        doc,
        '  Sempre respeitar o período de carência descrito na bula do medicamento ou orientação do médico veterinário antes de liberar o leite para o tanque',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      renderCheckboxList(doc, '', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight * 2, config)
    }

    doc.rect(startX, cursor, tableLimit, line, 'F')
  } else {
    renderCheckbox(
      doc,
      'Não há nenhuma ação a ser tomada para que não ocorra novamente',
      cursor,
      config,
    )
  }
  return cursor
}

function proximaVisitaAssinaturas(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    tableLimit,
    startX,
    smallGutterY,
    line,
    heightLimit,
    margin,
  } = config

  cursor += config.boxHeight + 30

  if (cursor + boxHeight * 10 > heightLimit) {
    doc.addPage()
    cursor = margin
  }

  const proximaVisita = relatorio.DataProximaVisita
    ? relatorio.DataProximaVisita
    : '-'

  doc.text('Data da próxima visita', tableLimit / 2 - 30, cursor + boxHeight)
  doc.text(
    proximaVisita,
    tableLimit / 2 - 5,
    cursor + boxHeight + smallBoxHeight,
  )

  cursor += boxHeight * 2

  const produtor = new Image()

  if (relatorio.AssinaturaProdutorOuResponsavel === '1') {
    produtor.src = relatorio.AssinaturaProdutor
    doc.addImage(
      produtor,
      'PNG',
      startX + 50,
      cursor - 140,
      100,
      145,
      '',
      'FAST',
      270,
    )
  } else {
    produtor.src = relatorio.AssinaturaResponsavel
    doc.addImage(
      produtor,
      'PNG',
      startX + 50,
      cursor - 140,
      100,
      145,
      '',
      'FAST',
      270,
    )
  }

  const consultor = new Image()
  consultor.src = relatorio.AssinaturaConsultor

  if (relatorio.AssinaturaConsultor) {
    doc.addImage(
      consultor,
      'PNG',
      tableLimit - 180,
      cursor - 140,
      100,
      145,
      '',
      'FAST',
      270,
    )
  }

  doc.rect(startX, cursor + 100, tableLimit / 2.1, line, 'F')

  if (relatorio.AssinaturaProdutorOuResponsavel === '1') {
    doc.text(
      relatorio.fazenda.produtor.Nome,
      tableLimit / 3.5,
      cursor + 100 + smallGutterY,
      null,
      null,
      'center',
    )
    doc.text(
      'Assinatura produtor(a)',
      tableLimit / 3.5,
      cursor + 115 + smallGutterY,
      null,
      null,
      'center',
    )
  } else {
    doc.text(
      relatorio.NomeResponsavel,
      tableLimit / 3.5,
      cursor + 100 + smallGutterY,
      null,
      null,
      'center',
    )
    doc.text(
      'Assinatura do responsável',
      tableLimit / 3.5,
      cursor + 115 + smallGutterY,
      null,
      null,
      'center',
    )
  }

  doc.rect(startX + 280, cursor + 100, tableLimit / 2.1, line, 'F')
  doc.text(
    relatorio.consultor.pessoafisica.Nome,
    startX + 420,
    cursor + 100 + smallGutterY,
    null,
    null,
    'center',
  )
  doc.text(
    'Assinatura consultor(a)',
    startX + 420,
    cursor + 115 + smallGutterY,
    null,
    null,
    'center',
  )

  cursor += boxHeight * 2

  return cursor
}

function rodapeImpressao(doc, config, cursor, text) {
  const { boxHeight, heightLimit, margin } = config
  const docWidth = doc.internal.pageSize.width
  const texto =
    utilsRelatorio.getDataAtual() +
    '       ' +
    `${ENV_APP.relUri}` +
    '        Pag. ' +
    text

  if (cursor + boxHeight * 2 > heightLimit) {
    doc.addPage()
    cursor = margin
  }

  doc.text(texto, docWidth / 2, heightLimit + 7, 'center')
}

export default data => {
  return new Promise(function (resolve, reject) {
    const MARGIN = 25
    const realStartY = 95
    var doc = new JsPDF({
      format: 'a4',
      compress: true,
      unit: 'pt',
    })
    var config = {
      startY: realStartY,
      startX: MARGIN,
      smallGutterY: 12,
      smallGutterX: 6,
      line: 0.3,
      tableLimit: doc.internal.pageSize.width - MARGIN - MARGIN,
      heightLimit: doc.internal.pageSize.height - MARGIN,
      margin: MARGIN,
      lineColor: '#000',
      boxHeight: 27,
      smallBoxHeight: 18,
      gutterX: 14,
      gutterY: 23,
      smallFontSize: 6.5,
      fontSize: 10,
      largefontSize: 16,
      mediumFontSize: 12,
      logo: 'img/' + `${ENV_APP.imgFileLogin}`,
      bodyStarY: realStartY + 25 * 8.5,
    }

    try {
      HtmlToCanvas(document.querySelector('#chart-rebanho')).then(canvas => {
        var dataURL = canvas.toDataURL()
        doc.addImage(
          dataURL,
          'PNG',
          config.startX * 2.8,
          config.startY + config.boxHeight * 6,
          456,
          200,
        )
        var cursor = 0
        header(doc, config, data)
        subHeader(doc, data, config)
        config.startY = config.startY + config.boxHeight * 4 + 50
        cursor = composicaoRebanho(doc, data, config, cursor)
        config.startY = config.startY + config.boxHeight * 12 + 50
        indicadoresQualidade(doc, data, config)
        config.startY = config.startY + config.boxHeight * 12 + 50
        cursor = testeAntibiotico(doc, data, config, cursor)
        config.startY = cursor + config.boxHeight - 3
        cursor = identificacaoAnimal(doc, data, config, cursor)
        config.startY = cursor + config.boxHeight - 3
        cursor = anotacoes(doc, data, config, cursor)
        config.startY = cursor + config.boxHeight - 3
        cursor = divisaoLote(doc, data, config, cursor)
        config.startY = cursor + config.boxHeight - 3
        cursor = instalacaoEGestao(doc, data, config, cursor)
        config.startY = cursor + config.boxHeight - 3
        cursor = conclusao(doc, data, config, cursor)
        config.startY = cursor + config.boxHeight - 3
        cursor = proximaVisitaAssinaturas(doc, data, config, cursor)
        const pageCount = doc.internal.getNumberOfPages()

        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i)
          rodapeImpressao(
            doc,
            config,
            cursor + config.boxHeight,
            String(i) + ' de ' + String(pageCount),
          )
        }
        doc
          .save(
            `relatorio-atb-${data.NumeroVisita}-${data.fazenda.pessoajuridica.NomeFantasia}.pdf`,
            { returnPromise: true },
          )
          .then(() => {
            return resolve()
          })
      })
    } catch (err) {
      reject(err)
    }
  })
}
