<template>
  <v-card
    v-if="relatorio.TesteAntibiótico || relatorio.TesteAntibióticoObs"
    class="ma-4"
  >
    <v-card-title style="text-align: left">Teste de antibiótico</v-card-title>
    <v-card-text>
      <v-row class="pa-3">
        <v-col
          v-if="relatorio.TesteAntibiótico == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'O leite testado apresentou resultado positivo para antibiótico'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <v-col v-if="relatorio.TesteAntibióticoRecomendacao1" cols="12">
              <span class="value-title">
                {{
                  'Aminoglicosídeos. Principais Fármacos: Neomicina, Estreptomicina e Gentamicina'
                }}
              </span>
              <br />
            </v-col>
            <v-col v-if="relatorio.TesteAntibióticoRecomendacao2" cols="12">
              <span class="value-title">
                {{
                  'Quinolonas. Principais Fármacos: Enrofloxacina, Ciprofloxacina e Norfloxacina'
                }}
              </span>
              <br />
            </v-col>
            <v-col v-if="relatorio.TesteAntibióticoRecomendacao3" cols="12">
              <span class="value-title">
                {{
                  'Cefalosporinas. Principais Fármacos: Ceftiofur; Cefquinoma e Cefaperazone'
                }}
              </span>
              <br />
            </v-col>
            <v-col v-if="relatorio.TesteAntibióticoRecomendacao4" cols="12">
              <span class="value-title">
                {{
                  'Penicilinas. Principais Fármacos: Penicila, Amoxicilina e Cloxacilina'
                }}
              </span>
              <br />
            </v-col>
            <v-col v-if="relatorio.TesteAntibióticoRecomendacao5" cols="12">
              <span class="value-title">
                {{ 'Tetraciclinas. Principais Fármacos: Oxitetraciclina' }}
              </span>
              <br />
            </v-col>
            <v-col v-if="relatorio.TesteAntibióticoRecomendacao6" cols="12">
              <span class="value-title">
                {{ 'Sulfonamidas. Principais Fármacos: Sulfas e Tremitropina' }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>
        <v-col
          v-else-if="relatorio.TesteAntibiótico != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'O leite testado apresentou resultado positivo para antibiótico'
            }}
          </span>
        </v-col>

        <v-col
          v-if="relatorio.TesteAntibióticoObs != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="value-title">
            {{ 'Observação:' }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ relatorio.TesteAntibióticoObs }}
            </span>
          </v-col>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { convertNumberBR } from '../../../../utils/masks'
import { ENV_APP } from '../../../../../env'
export default {
  name: 'IndicadoresQualidade',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    convertNumberBR,
    getImagem(idImagem) {
      return `${ENV_APP.apiUri}/download-image/${idImagem}`
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
