<template>
  <v-card
    v-if="
      relatorio.AnotacoesTratamento ||
      relatorio.RegistroDeSecagem ||
      relatorio.RegistrosAtualizados ||
      relatorio.AnotacoesObs
    "
    class="ma-4"
  >
    <v-card-title style="text-align: left"
      >Anotações de tratamento</v-card-title
    >
    <v-card-text>
      <v-row class="pa-3">
        <v-col
          v-if="relatorio.AnotacoesTratamento == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{ 'A propriedade possui registro de secagem dos animais' }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.AnotacoesTratamento != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'A propriedade possui registro de secagem dos animais' }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col v-if="relatorio.AnotacoesTratamentoRecomendacao1" cols="12">
              <span class="value-title">
                {{
                  'Iniciar o registro de tratamento dos animais (caderno, ficha ou software)'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.RegistroDeSecagem == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{ 'A propriedade possui registro de secagem dos animais' }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.RegistroDeSecagem != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'A propriedade possui registro de secagem dos animais' }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col v-if="relatorio.RegistroDeSecagemRecomendacao1" cols="12">
              <span class="value-title">
                {{
                  'Iniciar o registro de secagem dos animais (caderno, ficha ou software)'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.RegistrosAtualizados == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Os registros são atualizado frequentemente (no mínimo semanal)'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.RegistrosAtualizados != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Os registros são atualizado frequentemente (no mínimo semanal)'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col v-if="relatorio.RegistrosAtualizadosRecomendacao1" cols="12">
              <span class="value-title">
                {{ 'Atualizar os registro com frequência (no mínimo semanal)' }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>
        <v-col
          v-if="relatorio.AnotacoesObs != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="value-title">
            {{ 'Observação:' }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ relatorio.AnotacoesObs }}
            </span>
          </v-col>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { convertNumberBR } from '../../../../utils/masks'
import { ENV_APP } from '../../../../../env'
export default {
  name: 'IndicadoresQualidade',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    convertNumberBR,
    getImagem(idImagem) {
      return `${ENV_APP.apiUri}/download-image/${idImagem}`
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
