<template>
  <v-card
    v-if="
      relatorio.LoteEspecifico ||
      relatorio.AnimaisSeparadosPeriodoCarencia ||
      relatorio.PropriedadeLinhaOrdenha ||
      relatorio.DivisaoLoteObs
    "
    class="ma-4"
  >
    <v-card-title style="text-align: left">Divisão de lotes</v-card-title>
    <v-card-text>
      <v-row class="pa-3">
        <v-col
          v-if="relatorio.LoteEspecifico == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'A propriedade possui um lote específico para animais em tratamento'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.LoteEspecifico != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'A propriedade possui um lote específico para animais em tratamento'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col v-if="relatorio.LoteEspecificoRecomendacao1" cols="12">
              <span class="value-title">
                {{ 'Separar os animais em lotes específicos de tratamento' }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.AnimaisSeparadosPeriodoCarencia == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'A propriedade mantém os animais separados até o término do período de carência'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.AnimaisSeparadosPeriodoCarencia != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'A propriedade mantém os animais separados até o término do período de carência'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col
              v-if="relatorio.AnimaisSeparadosPeriodoCarenciaRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Manter os animais no lote de tratamento até terminar o período de carência do medicamento'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.PropriedadeLinhaOrdenha == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'A propriedade faz linha de ordenha (os animais tratados e/ou em carência são ordenhado no final)'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.PropriedadeLinhaOrdenha != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'A propriedade faz linha de ordenha (os animais tratados e/ou em carência são ordenhado no final)'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col
              v-if="relatorio.PropriedadeLinhaOrdenhaRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Realizar a ordenha dos animais tratados ou em período de carência por último'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>
        <v-col
          v-if="relatorio.DivisaoLoteObs != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="value-title">
            {{ 'Observação:' }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ relatorio.DivisaoLoteObs }}
            </span>
          </v-col>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { convertNumberBR } from '../../../../utils/masks'
import { ENV_APP } from '../../../../../env'
export default {
  name: 'IndicadoresQualidade',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    convertNumberBR,
    getImagem(idImagem) {
      return `${ENV_APP.apiUri}/download-image/${idImagem}`
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
