<template>
  <v-card
    v-if="
      relatorio.IdentificacaoAnimalPorBrinco ||
      relatorio.IdentificacaoVisualTratamento ||
      relatorio.IdentificacaoVisualColostro ||
      relatorio.IdentificacaoVisualNaoOrdenha ||
      relatorio.IdentificacaoObs
    "
    class="ma-4"
  >
    <v-card-title style="text-align: left"
      >Identificação dos animais</v-card-title
    >
    <v-card-text>
      <v-row class="pa-3">
        <v-col
          v-if="relatorio.IdentificacaoAnimalPorBrinco == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{ 'Os animais possuem identificação por brinco' }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.IdentificacaoAnimalPorBrinco != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'Os animais possuem identificação por brinco' }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col
              v-if="relatorio.IdentificacaoAnimalPorBrincoRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{ 'Identificar os animais por brinco' }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.IdentificacaoVisualTratamento == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Os animais medicados apresentam algum tipo de marcação visual que identifique a realização de tratamento'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.IdentificacaoVisualTratamento != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Os animais medicados apresentam algum tipo de marcação visual que identifique a realização de tratamento'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col
              v-if="relatorio.IdentificacaoVisualTratamentoRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Marcar os animais tratados. Exemplo: marcar com pulseira ou tinta vermelha'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.IdentificacaoVisualColostro == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Os animais recém paridos possuem algum tipo de marcação visual que identifique a presença de colostro ou leite de transição'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.IdentificacaoVisualColostro != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Os animais recém paridos possuem algum tipo de marcação visual que identifique a presença de colostro ou leite de transição'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col
              v-if="relatorio.IdentificacaoVisualColostroRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Marcar os animais recém paridos em produção de colostro ou leite de transição. Exemplo: marcar com pulseira ou tinta amarela'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.IdentificacaoVisualNaoOrdenha == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'As vacas secas possuem algum tipo de marcação visual que identifique que aquele animal não pode ser ordenhado'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.IdentificacaoVisualNaoOrdenha != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'As vacas secas possuem algum tipo de marcação visual que identifique que aquele animal não pode ser ordenhado'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col
              v-if="relatorio.IdentificacaoVisualNaoOrdenhaRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Marcar os animais secos. Exemplo:  marcar com pulseira ou tinta azul'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>
        <v-col
          v-if="relatorio.IdentificacaoObs != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="value-title">
            {{ 'Observação:' }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ relatorio.IdentificacaoObs }}
            </span>
          </v-col>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { convertNumberBR } from '../../../../utils/masks'
import { ENV_APP } from '../../../../../env'
export default {
  name: 'IndicadoresQualidade',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    convertNumberBR,
    getImagem(idImagem) {
      return `${ENV_APP.apiUri}/download-image/${idImagem}`
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
