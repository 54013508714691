<template>
  <v-card>
    <v-card-title style="text-align: left">Conclusão</v-card-title>
    <v-card-text>
      <v-card-title class="text-h4 font-weight-bold" style="text-align: left"
        >Qual a(s) principal(ais) causa(s) da contaminação do leite por
        antibiótico?</v-card-title
      >
      <v-row class="pa-3">
        <v-col
          v-if="relatorio['TesteAntibióticoRecomendacao1'] == '1'"
          cols="12"
          sm="12"
          lg="12"
          xl="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Aminoglicosídeos. Principais fármacos: Neomicina, Estreptomicina e
            Gentamicina</span
          >
        </v-col>
        <v-col
          v-if="relatorio['TesteAntibióticoRecomendacao2'] == '1'"
          cols="12"
          sm="12"
          lg="12"
          xl="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Quinolonas. Principais fármacos: Enrofloxacina, Ciprofloxacina e
            Norfloxacina</span
          >
        </v-col>
        <v-col
          v-if="relatorio['TesteAntibióticoRecomendacao3'] == '1'"
          cols="12"
          sm="12"
          lg="12"
          xl="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Cefalosporinas. Principais fármacos: Ceftiofur; Cefquinoma e
            Cefaperazone</span
          >
        </v-col>
        <v-col
          v-if="relatorio['TesteAntibióticoRecomendacao4'] == '1'"
          cols="12"
          sm="12"
          lg="12"
          xl="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Penicilinas. Principais fármacos: Penicila, Amoxicilina e
            Cloxacilina</span
          >
        </v-col>
        <v-col
          v-if="relatorio['TesteAntibióticoRecomendacao5'] == '1'"
          cols="12"
          sm="12"
          lg="12"
          xl="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Tetraciclinas. Principais fármacos: Oxitetraciclina</span
          >
        </v-col>
        <v-col
          v-if="relatorio['TesteAntibióticoRecomendacao6'] == '1'"
          cols="12"
          sm="12"
          lg="12"
          xl="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Sulfonamidas. Principais fármacos: Sulfas e Tremitropina</span
          >
        </v-col>
      </v-row>

      <v-row
        v-if="
          !relatorio['TesteAntibióticoRecomendacao1'] &&
          !relatorio['TesteAntibióticoRecomendacao2'] &&
          !relatorio['TesteAntibióticoRecomendacao3'] &&
          !relatorio['TesteAntibióticoRecomendacao4'] &&
          !relatorio['TesteAntibióticoRecomendacao5'] &&
          !relatorio['TesteAntibióticoRecomendacao6']
        "
        class="px-2"
      >
        <v-col cols="12" sm="12" lg="12" xl="12" class="col-bordered">
          <span class="key-title"
            >Não ah nenuma contaminação do leite por antibiótico</span
          >
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text>
      <v-card-title class="text-h4 font-weight-bold" style="text-align: left"
        >Qual a(s) principal(ais) ação(ões) a serem tomadas para que não ocorra
        novamente?</v-card-title
      >
      <v-row class="pa-3">
        <v-col
          v-if="relatorio['IdentificacaoAnimalPorBrincoRecomendacao1'] == '1'"
          cols="12"
          sm="12"
          lg="12"
          xl="12"
          class="col-bordered"
        >
          <span class="key-title">Identificar os animais por brinco</span>
        </v-col>
        <v-col
          v-if="relatorio['IdentificacaoVisualTratamentoRecomendacao1'] == '1'"
          cols="12"
          sm="12"
          lg="12"
          xl="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Marcar os animais tratados. Exemplo: marcar com pulseira ou tinta
            vermelha</span
          >
        </v-col>
        <v-col
          v-if="relatorio['IdentificacaoVisualColostroRecomendacao1'] == '1'"
          cols="12"
          sm="12"
          lg="12"
          xl="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Marcar os animais recém paridos em produção de colostro ou leite de
            transição. Exemplo: marcar com pulseira ou tinta amarela</span
          >
        </v-col>
        <v-col
          v-if="relatorio['IdentificacaoVisualNaoOrdenhaRecomendacao1'] == '1'"
          cols="12"
          sm="12"
          lg="12"
          xl="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Marcar os animais secos. Exemplo: marcar com pulseira ou tinta
            azul</span
          >
        </v-col>
        <v-col
          v-if="relatorio['AnotacoesTratamentoRecomendacao1'] == '1'"
          cols="12"
          sm="12"
          lg="12"
          xl="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Iniciar o registro de tratamento dos animais (caderno, ficha ou
            software)</span
          >
        </v-col>
        <v-col
          v-if="relatorio['RegistroDeSecagemRecomendacao1'] == '1'"
          cols="12"
          sm="12"
          lg="12"
          xl="12"
          class="col-bordered"
        >
          <span class="key-title"
            >niciar o registro de secagem dos animais (caderno, ficha ou
            software)</span
          >
        </v-col>
        <v-col
          v-if="relatorio['RegistrosAtualizadosRecomendacao1'] == '1'"
          cols="12"
          sm="12"
          lg="12"
          xl="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Atualizar os registro com frequência (no mínimo semanal)</span
          >
        </v-col>
        <v-col
          v-if="relatorio['LoteEspecificoRecomendacao1'] == '1'"
          cols="12"
          sm="12"
          lg="12"
          xl="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Separar os animais em lotes específicos de tratamento</span
          >
        </v-col>
        <v-col
          v-if="
            relatorio['AnimaisSeparadosPeriodoCarenciaRecomendacao1'] == '1'
          "
          cols="12"
          sm="12"
          lg="12"
          xl="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Manter os animais no lote de tratamento até terminar o período de
            carência do medicamento</span
          >
        </v-col>
        <v-col
          v-if="relatorio['PropriedadeLinhaOrdenhaRecomendacao1'] == '1'"
          cols="12"
          sm="12"
          lg="12"
          xl="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Realizar a ordenha dos animais tratados ou em período de carência
            por último</span
          >
        </v-col>
        <v-col
          v-if="relatorio['TratamentoFeitoAposOrdenhaRecomendacao1'] == '1'"
          cols="12"
          sm="12"
          lg="12"
          xl="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Realizar o tratamento dos animais enfermos após a ordenha dos
            animais sadios</span
          >
        </v-col>
        <v-col
          v-if="relatorio['AnimaisIndicacaoVeterinarioRecomendacao1'] == '1'"
          cols="12"
          sm="12"
          lg="12"
          xl="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Procurar um médico veterinário para que possa orientar o tratamento
            correto aos animais</span
          >
        </v-col>
        <v-col
          v-if="relatorio['TratamentoComBulaRecomendacao1'] == '1'"
          cols="12"
          sm="12"
          lg="12"
          xl="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Sempre olhar a bula do medicamento antes de aplica-lo</span
          >
        </v-col>
        <v-col
          v-if="relatorio['SepararLeiteAnimaisTratamentoRecomendacao1'] == '1'"
          cols="12"
          sm="12"
          lg="12"
          xl="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Separar o leite dos animais em tratamento ou carência</span
          >
        </v-col>
        <v-col
          v-if="relatorio['RespeitarPeriodoBulaRecomendacao1'] == '1'"
          cols="12"
          sm="12"
          lg="12"
          xl="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Sempre respeitar o período de carência descrito na bula do
            medicamento ou orientação do médico veterinário antes de liberar o
            leite para o tanque</span
          >
        </v-col>
      </v-row>

      <v-row
        v-if="
          !relatorio['IdentificacaoAnimalPorBrincoRecomendacao1'] &&
          !relatorio['IdentificacaoVisualTratamentoRecomendacao1'] &&
          !relatorio['IdentificacaoVisualColostroRecomendacao1'] &&
          !relatorio['IdentificacaoVisualNaoOrdenhaRecomendacao1'] &&
          !relatorio['AnotacoesTratamentoRecomendacao1'] &&
          !relatorio['RegistroDeSecagemRecomendacao1'] &&
          !relatorio['RegistrosAtualizadosRecomendacao1'] &&
          !relatorio['LoteEspecificoRecomendacao1'] &&
          !relatorio['AnimaisSeparadosPeriodoCarenciaRecomendacao1'] &&
          !relatorio['PropriedadeLinhaOrdenhaRecomendacao1'] &&
          !relatorio['TratamentoFeitoAposOrdenhaRecomendacao1'] &&
          !relatorio['AnimaisIndicacaoVeterinarioRecomendacao1'] &&
          !relatorio['TratamentoComBulaRecomendacao1'] &&
          !relatorio['SepararLeiteAnimaisTratamentoRecomendacao1'] &&
          !relatorio['RespeitarPeriodoBulaRecomendacao1']
        "
        class="px-2"
      >
        <v-col cols="12" sm="12" lg="12" xl="12" class="col-bordered">
          <span class="key-title"
            >Não há nenhuma ação a ser tomada para que não ocorra
            novamente</span
          >
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'InformacoesGerais',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
