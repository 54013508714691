<template>
  <v-card
    v-if="
      relatorio.TratamentoFeitoAposOrdenha ||
      relatorio.AnimaisIndicacaoVeterinario ||
      relatorio.TratamentoComBula ||
      relatorio.SepararLeiteAnimaisTratamento ||
      relatorio.RespeitarPeriodoBula ||
      relatorio.TratamentoAnimaisObs
    "
    class="ma-4"
  >
    <v-card-title style="text-align: left">Tratamento dos animais</v-card-title>
    <v-card-text>
      <v-row class="pa-3">
        <v-col
          v-if="relatorio.TratamentoFeitoAposOrdenha == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{ 'O tratamento é feito após a ordenha dos animais sadios' }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.TratamentoFeitoAposOrdenha != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'O tratamento é feito após a ordenha dos animais sadios' }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col
              v-if="relatorio.TratamentoFeitoAposOrdenhaRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Realizar o tratamento dos animais enfermos após a ordenha dos animais sadios'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.AnimaisIndicacaoVeterinario == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{ 'Os tratamentos tem indicação de médico veterinário' }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.AnimaisIndicacaoVeterinario != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'Os tratamentos tem indicação de médico veterinário' }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col
              v-if="relatorio.AnimaisIndicacaoVeterinarioRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Procurar um médico veterinário para que possa orientar o tratamento correto aos animais'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.TratamentoComBula == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{ 'Os tratamentos são administrados de acordo com a bula' }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.TratamentoComBula != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'Os tratamentos são administrados de acordo com a bula' }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col v-if="relatorio.TratamentoComBulaRecomendacao1" cols="12">
              <span class="value-title">
                {{ 'Sempre olhar a bula do medicamento antes de aplica-lo' }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.SepararLeiteAnimaisTratamento == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'O leite dos animais em tratamento e/ou carência é direcionado para latões ou baldes que não irão para o tanque de refriamento'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.SepararLeiteAnimaisTratamento != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'O leite dos animais em tratamento e/ou carência é direcionado para latões ou baldes que não irão para o tanque de refriamento'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col
              v-if="relatorio.SepararLeiteAnimaisTratamentoRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{ 'Separar o leite dos animais em tratamento ou carência' }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.RespeitarPeriodoBula == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'A propriedade respeita o período de carência de acordo com a bula para liberar os animais para ordenha'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.RespeitarPeriodoBula != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'A propriedade respeita o período de carência de acordo com a bula para liberar os animais para ordenha'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col v-if="relatorio.RespeitarPeriodoBulaRecomendacao1" cols="12">
              <span class="value-title">
                {{
                  'Sempre respeitar o período de carência descrito na bula do medicamento ou orientação do médico veterinário antes de liberar o leite para o tanque'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>
        <v-col
          v-if="relatorio.TratamentoAnimaisObs != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="value-title">
            {{ 'Observação:' }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ relatorio.TratamentoAnimaisObs }}
            </span>
          </v-col>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { convertNumberBR } from '../../../../utils/masks'
import { ENV_APP } from '../../../../../env'
export default {
  name: 'IndicadoresQualidade',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    convertNumberBR,
    getImagem(idImagem) {
      return `${ENV_APP.apiUri}/download-image/${idImagem}`
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
